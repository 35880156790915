import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

// Generic function to fetch configuration
async function getConfig(collection, document) {
  try {
    const configDocRef = doc(db, collection, document);
    const configDoc = await getDoc(configDocRef);
    if (configDoc.exists()) {
      return configDoc.data();
    } else {
      throw new Error(`No such document: ${collection}/${document}`);
    }
  } catch (error) {
    console.error("Error fetching config:", error);
    throw error;
  }
}

async function getChatConfigs() {
  return getConfig("chatConfig", "configDetails");
}

async function getImageConfigs() {
  return getConfig("imageConfig", "configDetails");
}

async function getSpeechConfigs() {
  return getConfig("speechConfig", "configDetails");
}

export { getChatConfigs, getImageConfigs, getSpeechConfigs };
